import 'jquery';
import $ from 'jquery';
import 'slick-carousel/slick/slick.min';
// import 'scrollmagic'






// смена слайдов при скролле
// init controller
// var controller = new ScrollMagic.Controller();
// console.log(controller);
// // create a scene
// var scene = new ScrollMagic.Scene({
//
// 	triggerElement: '.slider-container',
// 	duration: 1, // the scene should last for a scroll distance of 100px
// 	triggerHook: "onCenter",
//
// 	offset: 300 // start this scene after scrolling for 50px
// })
//
// 	.setPin('.slider-container') // pins the element for the the scene's duration
// 	.addTo(controller); // assign the scene to the controller
// console.log(scene);
//
//
// scene.on("enter", function (event) {
// 	// add custom action
// 	// page.classList.add('page-hidden');
// 	window.onmousewheel.preventDefault();
//
// 	console.log('старт сцены');
// })
// 	.on("end", function (event) {
// 		// reset my element to start position
// 		page.classList.remove('page-hidden');
// 		console.log('конец сцены');
//
// 	});
//
// var start = scene.scrollOffset();
// var end = scene.scrollOffset() + scene.duration();
// console.log('the scene starts at', start, 'and ends at', end);

// let scrollPosition = window.scrollY;
// let slidePosition = 1045;
// let maxPosition = 1060;
//
// window.addEventListener('scroll', () => {
// 	scrollPosition = window.scrollY;
// 	console.log(scrollPosition);
// });
//
// const page = document.querySelector('.page');
// function scrollSlide(scrollPosition) {
// 	scrollPosition = window.scrollY;
// 	window.requestAnimationFrame(scrollSlide);
//
// 	if (scrollPosition >= slidePosition && scrollPosition <= maxPosition) {
// 		console.log('больше:', slidePosition);
// 		// page.classList.add('page-hidden');
//
// 	} else if (scrollPosition <= slidePosition) {
// 		// console.log('меньше:', slidePosition);
// 	}
// }
//
// window.requestAnimationFrame(scrollSlide);
// Скролл
const slider = $('.slider-container');
// slider
// 	.slick({
// 		dots: true
// 	});

slider.on('wheel', (function (event) {
	// event.preventDefault();
	let targ = event.target.closest('.slider-row');
	console.log('targ:', targ);

	let slickIndex = targ.getAttribute('data-slick-index');
	console.log('slickIndex:', slickIndex);

	let wheelPos = event.originalEvent.deltaY;
	console.log(wheelPos);
	if (wheelPos < 0) {
		$(this).slick('slickPrev');
	} else {
		$(this).slick('slickNext');
	}
	if (slickIndex != 2) {
		// page.classList.remove('page-hidden');
		// console.log('ИНДЕКС СЛАЙДА РАВЕН 2');
		event.preventDefault();
		return;

	}

	// let stopScroll = false;

	// let slickIndex = document.querySelector('[data-slick-index = "2"]')
	// let sliderRow = document.querySelector('.slider-row')
	// let slickIndex = sliderRow.getAttribute('data-slick-index');
	// console.log('sliderRow:', sliderRow);
	// console.log('slickIndex', slickIndex);

}));

// карусель на bootstrap
$(document).ready(function () {
	$('.slider-container').slick({
		dots: true,
		arrows: false,
		fade: true,
		// centerMode: true,
		// focusOnSelect: true
		prevArrow: $('.slick-prev'),
		nextArrow: $('.slick-next'),
		infinite: false,
		draggable: false,
		// mobileFirst: true,
		responsive: [
			{
				breakpoint: 1260,
				settings: 'unslick'
			},
		]

	});

	$('.demo__items').slick({
		asNavFor: '.demo-images',
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		focusOnSelect: true,

	});

	$('.demo-images').slick({
		asNavFor: '.demo__items',
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		dots: false,
		arrows: false,
		infinite: false,
		draggable: false,
	});


	$('.slider').slick({
		asNavFor: '.wrapper__links',
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		speed: 350,
		cssEase: 'linear',
		lazyLoad: 'ondemand',
		lazyLoadBuffer: 0,
		draggable: false,
		dragging: false,
		swipe: false,
	});

	$('.wrapper__links').slick({
		asNavFor: '.slider',
		infinite: false,
		arrows: false,
		dots: false,
		// slidesToScroll: 2,
		variableWidth: true,
		// slidesToShow: 5,
		focusOnSelect: true,
		// centerMode: true,
		responsive: [
			{
				breakpoint: 1920,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,

				}
			},
			{
				breakpoint: 1260,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToScroll: 1,

				}
			}
		]
	});

$('.slider__case').slick({
	slidesToShow: 2.3,
	slidesToScroll: 1,
	infinite: false,
	arrows: true,
	dots: false,
	focusOnSelect: true,

	responsive: [
		{
			breakpoint: 1920,
			settings: {
				slidesToShow: 1.9,
				slidesToScroll: 1,
				arrows: true,
				dots: true,
				// centerMode: true,
			}
		},
		{
			breakpoint: 1260,
			settings: {
				slidesToShow: 1.4,
				slidesToScroll: 1,
				dots: true,
				arrows: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 1.4,
				slidesToScroll: 1,
				arrows: false,
				dots: true,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots: true,
			}
		}
	]
})


	$('.faq__links').slick({
		asNavFor: '.faq-block',
		variableWidth: true,

		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		centerMode: true,
		focusOnSelect: true,
		draggable: false,


		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
					asNavFor: '.faq-block',
				}
			}
		]

	});

	const faqBlock = $('.faq-block');

	faqBlock.slick({
		asNavFor: '.faq__links',
		arrows: false,
		draggable: false,
		infinite: false,
		adaptiveHeight: true,

	});
	window.fffbbb = faqBlock;
	faqBlock.on('beforeChange', function () {
		window.fffbbb.find('.slick-slide').height('auto');
	});

});
